import { SET_AUTH_DATA, SET_USER_DATA, SET_ADDRESS, SET_ORDER, SET_MY_ORDERS, SET_XENDIT_FUNCTION, SET_COUNTRIES } from "../types";

const initialState = {
  authData: {},
  user_data: null,
  addresses: null,
  order: null,
  my_orders: null,
  xendit_function: null,
  countries: null
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_AUTH_DATA:
      return { ...state, authData: payload }
    case SET_USER_DATA:
      return { ...state, user_data: payload }
    case SET_ADDRESS:
      return { ...state, addresses: payload }
    case SET_ORDER:
      return { ...state, order: payload }
    case SET_MY_ORDERS:
      return { ...state, my_orders: payload }
    case SET_XENDIT_FUNCTION:
      return { ...state, xendit_function: payload }
    case SET_COUNTRIES:
      return { ...state, countries: payload }
    default:
      return state;
  }
};

export default reducer;
