import { gql } from "@apollo/client"

//!Get MyData
export const GET_MY_DATA = gql`
  query getMyData($currency: String) {
    me: me {
      id
      name
      email
      gender
      dob
      point
      created_at
      updated_at
      phone
      email_verified_at
    }
    addresses: addresses {
      id
      name
      email
      phone
      address
      country
      state
      city
      subdistrict
      postcode
      is_primary
      longitude
      latitude
      geolabel
    }
    carts: carts(language: "en", currency: $currency) {
      grand_total
      subtotal
      discount_coupon
      weight
      volume
      realweight
      realvolume
      finalweight
      realfinalweight
      point
      shipping_fee
      tax
      free_shipping
      discount_ongkir
      insurance_fee
      free_shipping_value
      must_insurance
      exchange_rate
      items {
        id
        title
        image
        product_id
        sku_id
        quantity
        price
        stock
        sell_price
        for_holder
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      status
      message
    }
  }
`

export const Q_LOCATION = gql`
  query LOCATION($s: String!) {
    searchCitySubdistrict(s: $s) {
      city
      id
      subdistrict
      postcodes
      state
    }
  }
`

export const Q_ADDRESSES = gql`
  query ADDRESS {
    addresses {
      id
      name
      email
      phone
      address
      country
      state
      city
      subdistrict
      postcode
      is_primary
      longitude
      latitude
      geolabel
    }
  }
`

export const Q_CATEGORIES = gql`
  query CATEGORIES {
    categories(filter: { language: "en" }) {
      title
      id
    }
  }
`

export const Q_CATALOGS = gql`
  query CATALOGS($is_featured: Int, $currency: String) {
    catalogs(
      filter: { language: "en", is_featured: $is_featured, currency: $currency }
    ) {
      products {
        id
        slug
        title
        price
        images
        sell_price
        for_holder
        is_wishlist
        manage_stock
        relateds {
          title
        }
        firstcategory {
          id
          title
        }
        skus {
          sku_code
        }
      }
    }
  }
`

export const Q_SEARCH_CATALOGS = gql`
  query SEARCH_CATALOGS($s: String, $page: Int, $currency: String) {
    catalogs(
      filter: {
        language: "en"
        s: $s
        limit: 9
        page: $page
        currency: $currency
      }
    ) {
      products {
        id
        title
        images
        for_holder
        manage_stock
        price
        sell_price
        relateds {
          title
        }
        firstcategory {
          id
          title
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
    }
    catalogsFeatured: catalogs(
      filter: { language: "en", is_featured: 1, currency: $currency }
    ) {
      products {
        id
        slug
        title
        price
        images
        sell_price
        for_holder
        is_wishlist
        manage_stock
        relateds {
          title
        }
        firstcategory {
          id
          title
        }
        skus {
          sku_code
        }
      }
    }
  }
`

// export const Q_CATALOGS_SEARCH = gql`
// query CATALOGS {
//   catalogs(filter: {language: "en"}) {
//     products {
//       id
//       title
//       images
//       manage_stock
//       price
//       sell_price
//       relateds {
// 				title
//       }
//     }
//   }
// }
// `

export const Q_PRODUCT = gql`
  query PRODUCT($id: ID!, $currency: String) {
    product(language: "en", id: $id, currency: $currency) {
      id
      title
      description
      images
      manage_stock
      price
      sell_price
      for_holder
      skus {
        id
        option_detail_key1
        option_detail_key2
        sku_code
        stock
        price
        sell_price
        image_no
      }
      relateds {
        id
        title
        images
        sell_price
        for_holder
        firstcategory {
          id
          title
        }
      }
      firstcategory {
        id
        title
      }
      secondcategory {
        id
        title
      }
      thirdcategory {
        id
        title
      }
      options {
        title
        values {
          title
          value
        }
      }
    }
  }
`

export const Q_STOCK = gql`
  query STOCK($id: ID!) {
    stock(sku_id: $id) {
      stock
      status
      message
      price
      sell_price
    }
  }
`

export const Q_MY_CART = gql`
  query CARTS($coupon: String, $currency: String) {
    carts(language: "en", coupon: $coupon, currency: $currency) {
      grand_total
      subtotal
      discount_coupon
      weight
      volume
      realweight
      realvolume
      finalweight
      realfinalweight
      point
      shipping_fee
      tax
      free_shipping
      discount_ongkir
      insurance_fee
      free_shipping_value
      must_insurance
      exchange_rate
      items {
        id
        title
        image
        product_id
        sku_id
        quantity
        price
        stock
        sell_price
        for_holder
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      status
      message
    }
  }
`

// shipping_subdistrict: "canberra",
//   shipping_postcode: "2601",
//     shipping_country: "Australia",
//       shipping_city: "canberra",

export const Q_SHIPPING = gql`
  query SHIPPING_METHOD(
    $shipping_postcode: String!
    $shipping_subdistrict: String!
    $shipping_city: String!
    $shipping_country: String!
    $currency: String
  ) {
    shippingmethods(
      shipping: {
        shipping_postcode: $shipping_postcode
        shipping_subdistrict: $shipping_subdistrict
        shipping_country: $shipping_country
        shipping_city: $shipping_city
      }
      currency: $currency
    ) {
      id
      price
      service_name
      insurance_fee
      name
    }
  }
`

export const Q_FAQ = gql`
  query FAQ($s: String) {
    faqs(language: "en", s: $s) {
      id
      title
      description
    }
  }
`

export const Q_PAYMENT_METHODS = gql`
  query PAYMENT_METHOD {
    paymentmethods {
      title
      fee_pct
      fee_flat
      logo
    }
  }
`
export const Q_JOURNAL_LIST = gql`
  query Journals($limit: Int) {
    blogs(language: "en", limit: $limit) {
      datas {
        id
        title
        slug
        image
        square_image
        portrait_image
        excerpt
        publish_date
        category {
          title
        }
      }
    }
  }
`

export const Q_Journal = gql`
  query Journal($id: ID!) {
    blog(language: "en", id: $id) {
      id
      title
      image
      square_image
      portrait_image
      content
      slug
      excerpt
      publish_date
      category {
        title
      }
    }
  }
`

export const Q_MY_ORDERS = gql`
  query MY_ORDERS($page: Int) {
    myorders(language: "en", limit: 5, page: $page) {
      datas {
        id
        order_no
        total_amount
        shipping_fee
        discount_ongkir
        total_discount
        insurance
        tax
        unique_code
        payment_service_fee
        payment_method
        currency
        exchange_rate
        final_amount
        charge_service_fee
        pay_with_point
        billing {
          id
          name
          phone
          email
          address
          country
          state
          city
          subdistrict
          postcode
        }
        shipping {
          id
          name
          phone
          email
          address
          country
          state
          city
          subdistrict
          postcode
          tracking_number
        }
        order_status {
          id
          title
        }
        details {
          title
          image
          sku_code
          price
          discount
          discount_coupon
          quantity
          notes
          option_1 {
            title
            value
          }
          option_2 {
            title
            value
          }
        }
        payment_method
        tracking_logs {
          created_at
          message
        }
        created_at
        updated_at
        status
        message
        payment_info {
          status
          account_number
          checkout_url
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
    }
  }
`

export const Q_COUNTRIES = gql`
  query Country {
    countries {
      id
      name
      code
    }
  }
`

export const Q_ORDER_DETAILS = gql`
  query ORDER_DETAIL($id: ID!) {
    order(language: "en", id: $id) {
      id
      order_no
      total_amount
      shipping_fee
      discount_ongkir
      total_discount
      insurance
      tax
      unique_code
      payment_service_fee
      currency
      exchange_rate
      final_amount
      charge_service_fee
      pay_with_point
      billing {
        name
        phone
        email
        address
        country
        state
        city
        subdistrict
        postcode
      }
      shipping {
        id
        name
        phone
        email
        address
        country
        state
        city
        subdistrict
        postcode
        shipping_method
        tracking_number
      }
      order_status {
        id
        title
      }
      details {
        title
        image
        sku_code
        product_id
        price
        discount
        discount_coupon
        quantity
        notes
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      payment_method
      tracking_logs {
        created_at
        message
      }
      created_at
      updated_at
      status
      message
      payment_info {
        status
        account_number
        checkout_url
      }
    }
  }
`

export const Q_MY_CATALOGS = gql`
  query MY_CATALOGS($option: String, $value: String) {
    catalogs(
      filter: {
        language: "en"
        page: 1
        limit: 8
        options: { option: $option, value: $value }
      }
    ) {
      products {
        id
        title
        images
        manage_stock
        for_holder
        price
        sell_price
        relateds {
          title
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
      options {
        title
        values {
          title
          value
        }
      }
    }
  }
`

export const Q_PAGES = gql`
  query GET_PAGES {
    pages(language: "en") {
      slug
      title
      seo {
        meta_title
        meta_description
        meta_keyword
      }
      content
    }
  }
`

// method_id: 9,
//   country: "Australia",
//     city: "canberra",
//       subdistrict: "canberra",
//         postcode: "2601"

export const Q_CARTS_GUEST = gql`
  query CARTS1($currency: String) {
    carts(language: "en", currency: $currency) {
      grand_total
      subtotal
      discount_coupon
      weight
      volume
      realweight
      realvolume
      finalweight
      realfinalweight
      point
      shipping_fee
      tax
      free_shipping
      discount_ongkir
      insurance_fee
      free_shipping_value
      must_insurance
      exchange_rate
      items {
        id
        title
        image
        product_id
        sku_id
        quantity
        price
        sell_price
        for_holder
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      status
      message
    }
  }
`

export const Q_CART_WITH_SHIPPING = gql`
  query CARTS(
    $delivery_id: Int!
    $postcode: String!
    $coupon: String
    $city: String!
    $subdistrict: String!
    $country: String!
    $currency: String
  ) {
    carts(
      language: "en"
      coupon: $coupon
      shipping: {
        method_id: $delivery_id
        postcode: $postcode
        city: $city
        country: $country
        subdistrict: $subdistrict
      }
      currency: $currency
    ) {
      grand_total
      subtotal
      discount_coupon
      weight
      volume
      realweight
      realvolume
      finalweight
      realfinalweight
      point
      shipping_fee
      tax
      free_shipping
      discount_ongkir
      insurance_fee
      free_shipping_value
      must_insurance
      exchange_rate
      items {
        id
        title
        image
        product_id
        sku_id
        quantity
        price
        sell_price
        for_holder
        option_1 {
          title
          value
        }
        option_2 {
          title
          value
        }
      }
      status
      message
    }
  }
`

export const Q_MY_CATALOGS3 = gql`
  query MY_CATALOGS1(
    $category_id: Int
    $price: String
    $page: Int
    $options: [OptionFilter]
  ) {
    catalogs(
      filter: {
        language: "en"
        page: $page
        limit: 9
        category_id: $category_id
        price: $price
        options: $options
        for_holder: 1
      }
    ) {
      products {
        id
        title
        images
        for_holder
        manage_stock
        price
        sell_price
        relateds {
          title
        }
        firstcategory {
          id
          title
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
      options {
        title
        values {
          title
          value
        }
      }
    }
  }
`

export const Q_MY_CATALOGS2 = gql`
  query MY_CATALOGS(
    $category_id: Int
    $price: String
    $page: Int
    $options: [OptionFilter]
    $currency: String
  ) {
    catalogs(
      filter: {
        language: "en"
        page: $page
        limit: 9
        category_id: $category_id
        price: $price
        options: $options
        currency: $currency
      }
    ) {
      products {
        id
        title
        images
        manage_stock
        price
        for_holder
        sell_price
        relateds {
          title
        }
        firstcategory {
          id
          title
        }
      }
      pagination {
        count
        current_page
        total_data
        last_page
      }
      options {
        title
        values {
          title
          value
        }
      }
    }
  }
`
