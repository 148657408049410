import { SET_CART_LIST, SET_CARTS, SET_IS_DRAWER } from "../types";

const initialState = {
    cartList: [],
    carts: null,
    shoppingBagMenu: {
        initial: false,
        clicked: null,
    },
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_CART_LIST:
            return { ...state, cartList: payload }
        case SET_CARTS:
            return { ...state, carts: payload }
        case SET_IS_DRAWER:
            return { ...state, shoppingBagMenu: payload };
        default:
            return state;
    }
};

export default reducer;
