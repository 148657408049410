export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_ORDER = "SET_ORDER";
export const SET_MY_ORDERS = "SET_MY_ORDERS";
export const SET_XENDIT_FUNCTION = "SET_XENDIT_FUNCTIONS";
export const SET_COUNTRIES = "SET_COUNTRIES"
export const SET_WISHLIST = "SET_WISHLIST";

// cart
export const SET_CART_LIST = 'SET_CART_LIST';
export const SET_CARTS = "SET_CARTS";
export const SET_IS_DRAWER = "SET_IS_DRAWER";

// product
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL';
export const SET_CATALOGS = "SET_CATALOGS";
export const SET_CATALOGS_FEATURED = "SET_CATALOGS_FEATURED";


// general
export const SET_TOAST = "SET_TOAST";
export const SET_CATEGORIES = "SET_CATEGORIES"
export const SET_TOAST_HEIGHT = "SET_TOAST_HEIGHT"
export const SET_CURRENCY = "SET_CURRENCY"
