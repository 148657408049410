import { SET_PRODUCT_DETAIL, SET_PRODUCT_LIST, SET_CATALOGS, SET_CATALOGS_FEATURED } from "../types";

const initialState = {
    catalogs: null,
    catalogsFeatured: null,
    productList: [],
    productDetail: {},
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_PRODUCT_LIST:
            return { ...state, productList: payload }
        case SET_PRODUCT_DETAIL:
            return { ...state, productDetail: payload }
        case SET_CATALOGS:
            return { ...state, catalogs: payload }
        case SET_CATALOGS_FEATURED:
            return { ...state, catalogsFeatured: payload }
        default:
            return state;
    }
};

export default reducer;
