import * as Type from "../types"

const initialState = {
  isToast: null,
  toastHeight: false,
  toastState: {
    message: null,
    status: null,
  },
  toastTime: 5000,
  categories: null,
  currency: { label: "IDR", value: 0 },
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case Type.SET_TOAST:
      let { show, message, status } = payload
      return { ...state, isToast: show, toastState: { message, status }, toastTime: payload?.toastTime || 5000 }
    case Type.SET_CATEGORIES:
      return { ...state, categories: payload }
    case Type.SET_TOAST_HEIGHT:
      return { ...state, toastHeight: payload }
    case Type.SET_CURRENCY:
      return { ...state, currency: payload }
    default:
      return state
  }
}

export default reducer
