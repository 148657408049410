import { combineReducers } from 'redux';
import authReducer from './authReducer';
import cartReducer from './cartReducer';
import productReducer from './productReducer';
import generalReducer from './generalReducer';

const appReducer = combineReducers({
    auth: authReducer,
    cart: cartReducer,
    product: productReducer,
    general: generalReducer,
})
const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer;